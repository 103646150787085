import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import AnonymousRoute from './pages/AnonymousRoute'
import ProtectedRoute from './pages/ProtectedRoute'

import './assets/styles/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/auth/Login'))
const Landing = React.lazy(() => import('./pages/Landing'))
const Manual = React.lazy(() => import('./pages/Manual'))
const Register = React.lazy(() => import('./pages/auth/Registration'))
const Page404 = React.lazy(() => import('./pages/errors/Page404'))
const Page500 = React.lazy(() => import('./pages/errors/Page500'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route exact path="/" name="Landing" element={<Landing />} />
              <Route exact path="/manual" name="Manual" element={<Manual />} />
              <Route element={<AnonymousRoute />}>
                <Route exact path="/login" name="Login" element={<Login />} />
                <Route exact path="/register" name="Register" element={<Register />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route exact path="/admin" name="Admin" element={<DefaultLayout />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>

        <ToastContainer />
      </div>
    )
  }
}

export default App
