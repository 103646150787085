import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { LS_JWT_TOKEN } from '../constants/common'

const AnonymousRoute = () => {
  const token = localStorage.getItem(LS_JWT_TOKEN)

  return token ? <Navigate to="/admin" /> : <Outlet />
}

export default AnonymousRoute
