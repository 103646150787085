import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { LS_JWT_TOKEN } from '../constants/common'

const ProtectedRoute = () => {
  const token = localStorage.getItem(LS_JWT_TOKEN)

  return token ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute
